import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../../assets/images/meta/sprints.png'
import tag from '../../assets/images/tags/sprints.svg'
import Layout from '../../components/Layout'
import CTABlock from '../../components/blocks/CTABlock'
import ColumnsBlock from '../../components/blocks/ColumnsBlock'
import { DarkLogoBlock } from '../../components/blocks/DarkLogoBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import IllustrationBlock from '../../components/blocks/IllustrationBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import MosaicBlock from '../../components/blocks/MosaicBlock'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import Box from '../../components/primitives/Box'
import Blurb from '../../components/system/Blurb'
import { responsiveScale } from '../../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/backgrounds/sprints.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    lead: file(relativePath: { eq: "images/lead/sprints.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    mosaicRight1: file(
      relativePath: { eq: "images/mosaic/sprint-listing.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680)
      }
    }
    mosaicLeft: file(
      relativePath: { eq: "images/mosaic/sprint-progress.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680)
      }
    }
    mosaicRight2: file(relativePath: { eq: "images/mosaic/sprint-focus.png" }) {
      childImageSharp {
        gatsbyImageData(width: 680)
      }
    }
    blurb1: file(relativePath: { eq: "images/product/overview/work-log.png" }) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    blurb2: file(relativePath: { eq: "images/product/overview/target.png" }) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    blurb3: file(relativePath: { eq: "images/product/surveys/question.png" }) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
  }
`

const SprintsPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Sprint metrics & analytics for Scrum teams"
      variant="dark"
      isNew
      description="Get the sprint analytics you need to improve planning and collaboration in your Scrum team."
      metaImage={MetaImage}
    >
      <HeroBlock
        tag={tag}
        title={<>Make every sprint better than&nbsp;the last</>}
        content="Get the sprint analytics you need to improve planning and collaboration in your Scrum team."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <Box backgroundColor="black" paddingY={40} paddingX={{ xs: 20, md: 36 }}>
        <DarkLogoBlock hideBackground />
      </Box>
      <LeadBlock
        heading={<>Sprint analytics that combine data from multiple sources</>}
        content="Instead of basing your sprint analytics on a single source like Jira, Swarmia allows you to get a full picture that combines issue insights with activity data from your Git repository."
      />
      <Box>
        <IllustrationBlock
          paddingTop={responsiveScale(32)}
          paddingBottom={responsiveScale(32)}
          image={getImage(data.lead)!}
          noPlaceHolder
          rounded
          shadow
        />
      </Box>
      <QuoteBlock person="christian" />
      <CTABlock title="Ready for better sprint analytics? Get started today." />
      <LeadBlock
        heading={<>Use relevant data to run better&nbsp;sprints</>}
        content="See all your sprints at once or drill down to the ongoing or past sprints to understand where your team gets stuck and how you can avoid that in the future."
      />
      <MosaicBlock
        title={<>Get a quick overview of your&nbsp;past sprints</>}
        content={
          <>
            See all your team’s past sprints at a glance and understand how much
            work gets added to ongoing sprints and carried over from past
            sprints to new ones.
          </>
        }
        image={getImage(data.mosaicRight1)!}
        imageAlign="right"
      />
      <MosaicBlock
        title={<>Use contextual data to improve planning</>}
        content={
          <>
            Teams that make it a habit to analyze their sprint metrics learn to
            adjust their planning and scoping for future sprints.
          </>
        }
        image={getImage(data.mosaicLeft)!}
      />
      <MosaicBlock
        title={<>Understand sprint focus</>}
        content={
          <>
            After each sprint, you can quickly see how much work went into new
            feature development vs. other types of work. These insights can be
            invaluable when you’re trying to pitch large refactoring projects or
            get approval for additional headcount.
          </>
        }
        image={getImage(data.mosaicRight2)!}
        imageAlign="right"
      />
      <QuoteBlock person="tim2" />
      <LeadBlock
        heading={<>Go beyond sprint analytics</>}
        content="In addition to sprint metrics, Swarmia gives your engineering organization a variety of insights and tools that help you ship better software faster."
      />

      <ColumnsBlock
        paddingTop={responsiveScale(64)}
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            title="Eliminate bottlenecks"
            text="Use the work log to see where engineering tasks get stuck and quickly unblock the flow of work."
            image={getImage(data.blurb1)}
          />,
          <Blurb
            key="2"
            title="Set targets as a team"
            text="Adopt working agreements and Slack notifications to automate feedback loops in your team."
            image={getImage(data.blurb2)}
          />,
          <Blurb
            key="3"
            title="Run developer experience surveys"
            text="Complement system metrics with qualitative feedback from your software engineers."
            image={getImage(data.blurb3)}
          />,
        ]}
      />
      <Box paddingBottom={responsiveScale(32)}>
        <CTABlock title="Start running better sprints today." />
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/measuring-software-development-productivity/',
            '/blog/how-we-use-swarmia-at-swarmia/',
            '/blog/data-driven-retrospectives-stop-fake-improvements/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

export default SprintsPage
